<template>
  <div class="tab-content">
    <div class="pagenamPT">
      <h3 class="pagename2">{{ $t('front.common.dailyPartner') }}</h3>
      <date-filter-Pt :startDate="startDate"
                      :endDate="endDate"
                      @update="updateDate"
                      @search="loadList" />
    </div>
    <article class="content-wrap">
      <div class="strTablescr">
        <div class="strTablePC">
          <table class="statTable">
            <thead>
              <tr>
                <th>{{ $t('front.common.partnerID') }}</th>
                <th class="text-right">{{ $t('front.common.partnerID') }}</th>
                <th class="text-right">{{ $t('front.common.charging') }}</th>
                <th class="text-right">{{ $t('front.stributor.m22') }}</th>
                <th class="text-right">{{ $t('front.stributor.m23') }}</th>
                <th class="text-right">{{ $t('front.cash.rolling') }}</th>
                <th class="text-right">{{ $t('front.bettingStatus.BET') }} - {{ $t('front.common.winning') }} - {{ $t('front.stributor.roll') }}</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="dailyPartnerList && dailyPartnerList.length > 0">
                <tr v-for="item in dailyPartnerList" :key="item.memId">
                  <td>{{ item.memId }} <span>({{ $t(item.partnerLevelName) }})</span></td>
                  <td class="text-right">{{ thousand(item.dUserCashIn || 0) }}</td>
                  <td class="text-right">{{ thousand(item.dUserCashOut || 0) }}</td>
                  <td class="text-right">{{ thousand(item.dBetAmt || 0) }}</td>
                  <td class="text-right">{{ thousand(item.dWinAmt || 0) }}</td>
                  <td class="text-right">{{ thousand(item.dRollingAmt || 0) }}</td>
                  <td class="text-right">{{ thousand(item.dBetWinRollingAmt || 0) }}</td>
                </tr>
              </template>
              <template v-else>
                <tr><td colspan="6">내역이 없습니다.</td></tr>
              </template>
            </tbody>
          </table>
        </div>
        <div class="strTableM">
          <div class="strTablePTM">
            <template v-if="dailyPartnerList && dailyPartnerList.length > 0">
              <template v-for="item in dailyPartnerList" :key="item.memId">
                <ul>
                  <li>
                    <em>파트너 ID</em>
                    <div>{{ item.memId }} <span class="nick">({{ item.partnerLevelName }})</span></div>
                  </li>
                  <li>
                    <em>충전하기</em>
                    <div>{{ thousand(item.dUserCashIn || 0) }}</div>
                  </li>
                  <li>
                    <em>환전하기</em>
                    <div>{{ thousand(item.dUserCashOut || 0) }}</div>
                  </li>
                  <li>
                    <em>베팅금</em>
                    <div>{{ thousand(item.dBetAmt || 0) }}</div>
                  </li>
                  <li>
                    <em>당첨금</em>
                    <div>{{ thousand(item.dWinAmt || 0) }}</div>
                  </li>
                  <li>
                    <em>롤링금</em>
                    <div>{{ thousand(item.dRollingAmt || 0) }}</div>
                  </li>
                  <li>
                    <em>베팅-당첨-롤링</em>
                    <div>{{ thousand(item.dBetWinRollingAmt || 0) }}</div>
                  </li>
                </ul>
              </template>
            </template>
            <template v-else>
              <ul>
                <li>내역이 없습니다.</li>
              </ul>
            </template>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>
<script>
import DateFilterPt from '@/components/ui/DateFilterPt'
import { getDateStr, thousand } from '@/libs/utils'
import { addDays } from 'date-fns'
import { mapState } from 'vuex'
import { getGameStaticsDailyPartnerV2 } from '@/api/v2/statics'

export default {
  name: 'DatePartner',
  components: { DateFilterPt },
  data () {
    return {
      dailyPartnerList: null,
      startDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 00:00:00'),
      endDate: getDateStr(addDays(new Date(), 0), 'yyyy-MM-dd 23:59:59')
    }
  },
  computed: {
    ...mapState([
      'userData',
      'gameCount',
      'commonCodeByOrder',
      'commonCodeByCode'
    ])
  },
  created () {
    this.loadList()
  },
  methods: {
    thousand,
    updateDate (value) {
      this.startDate = value.startDate
      this.endDate = value.endDate
    },
    loadList () {
      this.emitter.emit('Loading', true)
      this.dailyPartnerList = null

      const params = {
        memId: this.userData.memId,
        startDate: this.startDate,
        endDate: this.endDate
      }
      getGameStaticsDailyPartnerV2(params).then(res => {
        console.log('getGameStaticsDailyPartnerV2 : ', params, res.data)
        const result = res.data
        if (result.resultCode === '0') {
          const dailyPartnerList = result.data.dailyPartnerList
          dailyPartnerList.forEach(item => {
            const dBetAmt = Number(item.dBetAmt || 0)
            const dWinAmt = Number(item.dWinAmt || 0)
            const dRollingAmt = Number(item.dRollingAmt || 0)

            item.dBetWinRollingAmt = dBetAmt - dWinAmt - dRollingAmt
          })
          this.dailyPartnerList = dailyPartnerList
          this.emitter.emit('Loading', false)
        } else {
          this.emitter.emit('Loading', false)
          alert('다시 시도해주세요. ', result.resultMessage)
        }
      })
    }
  }
}
</script>
<style src="@/styles/pt.css"></style>
<style scoped>
@media (max-width: 1000px) {
  .pagenamPT {flex-direction: column;align-items: flex-start;}
}
</style>
